import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CachedIcon from "@mui/icons-material/Cached";
import SimCardIcon from "@mui/icons-material/SimCard";
import BusinessIcon from "@mui/icons-material/Business";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { RiOrganizationChart } from "react-icons/ri";
import Chart from "chart.js/auto";
import BreadCrumb from "../../../constants/BreadCrumb";
import {
  adminAllCompanylist,
  adminUpdateCompanydashboard,
} from "../../../constants/api";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";

const Dashboard = () => {
  const [isModify, setIsModify] = useState(true);
  const [data, setData] = useState([]);
  const [percent, setPercent] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const modulePieChartRef = useRef(null);
  const stackedBarChartRef = useRef(null);

  const handlePlan = (plan) => {
    setPlan(plan);
  };

  const handleCheck = (index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field]; // Toggle the current state
    setData(newData);
  };

  const handleAllCheck = (index) => {
    const newData = [...data];
    const company = newData[index];
    const allChecked =
      company.kms && company.hrms && company.pms && company.itams;
    newData[index] = {
      ...company,
      kms: !allChecked,
      hrms: !allChecked,
      pms: !allChecked,
      itams: !allChecked,
    };
    setData(newData);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const res = await axios.post(adminUpdateCompanydashboard, {
      companyarray: data,
    });
    if (res.status == 200) {
      setLoading(false);
      setIsModify(true);
    } else {
      setIsModify(false);
    }
  };

  useEffect(() => {
    if (!searchValue) {
      // If searchValue is empty, display all original data
      setFilteredData(data);
    } else {
      // Filter the original data based on searchValue
      const filtered = data.filter((company) =>
        company.company.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
      setFilteredData(filtered);
    }
  }, [searchValue, data]);

  //all company
  useEffect(() => {
    axios
      .post(adminAllCompanylist)
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setData(initialData);
        setPercent(res.data?.graphData);
      })
      .catch((err) => console.log(err));
  }, []);

  const renderModulePieChart = () => {
    const monthColors = ["#36BFFA", "#7CD4FD", "#B9E6FE", "#E0F2FE"];

    // Calculate total counts for each module
    const totalKMS = data.filter((company) => company.kms).length;
    const totalPMS = data.filter((company) => company.pms).length;
    const totalHRMS = data.filter((company) => company.hrms).length;
    const totalITAMS = data.filter((company) => company.itams).length;

    //Total Percent
    const kmsPercent = percent?.kms_percentage;
    const hrmsPercent = percent?.hrms_percentage;
    const pmsPercent = percent?.pms_percentage;
    const itamsPercent = percent?.itams_percentage;

    //basic percent
    const kmsBasic = percent?.basickms_percentage;
    const hrmsBasic = percent?.basichrms_percentage;
    const pmsBasic = percent?.basicpms_percentage;
    const itamsBasic = percent?.basicitams_percentage;

    //standard percent
    const kmsstandard = percent?.standardkms_percentage;
    const hrmsstandard = percent?.standardhrms_percentage;
    const pmsstandard = percent?.standardpms_percentage;
    const itamsstandard = percent?.standarditams_percentage;

    //premium percent
    const kmspremium = percent?.premiumkms_percentage;
    const hrmspremium = percent?.premiumhrms_percentage;
    const pmspremium = percent?.premiumpms_percentage;
    const itamspremium = percent?.premiumitams_percentage;

    const chartData = [
      {
        label: "KMS",
        count: totalKMS,
        percentage:
          plan == "basic"
            ? kmsBasic
            : plan == "standard"
            ? kmsstandard
            : plan == "premium"
            ? kmspremium
            : kmsPercent,
        backgroundColor: monthColors[0],
      },
      {
        label: "PMS",
        count: totalPMS,
        percentage:
          plan == "basic"
            ? pmsBasic
            : plan == "standard"
            ? pmsstandard
            : plan == "premium"
            ? pmspremium
            : pmsPercent,
        backgroundColor: monthColors[1],
      },
      {
        label: "HRMS",
        count: totalHRMS,
        percentage:
          plan == "basic"
            ? hrmsBasic
            : plan == "standard"
            ? hrmsstandard
            : plan == "premium"
            ? hrmspremium
            : hrmsPercent,
        backgroundColor: monthColors[2],
      },
      {
        label: "ITAMS",
        count: totalITAMS,
        percentage:
          plan == "basic"
            ? itamsBasic
            : plan == "standard"
            ? itamsstandard
            : plan == "premium"
            ? itamspremium
            : itamsPercent,
        backgroundColor: monthColors[3],
      },
    ];

    const chartElement = document.getElementById("module-pie");
    if (chartElement) {
      const chart = new Chart(chartElement, {
        type: "doughnut",
        data: {
          labels: chartData.map((item) => item.label),
          datasets: [
            {
              data: chartData.map((item) => item.percentage),
              backgroundColor: chartData.map((item) => item.backgroundColor),
            },
          ],
        },
        options: {
          cutout: 50,
          animation: true,
          plugins: {
            legend: {
              display: true,
              position: "right",
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (context) => {
                  const label = chartData[context.dataIndex].label;
                  const value = chartData[context.dataIndex].count;
                  const percentage = chartData[context.dataIndex].percentage;
                  return `${label}: (${percentage}%) ${value}`;
                },
              },
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true,
        },
      });
      modulePieChartRef.current = chart;
    }
  };

  const generateStackedBarChart = () => {
    const companies = data.slice(0, 15); // Get top 15 companies
    const companyNames = companies.map((company) => company.company);
    const modules = ["KMS", "HRMS", "PMS", "ITAMS"];
    const colors = ["#36BFFA", "#7CD4FD", "#B9E6FE", "#E0F2FE"];

    const ctx = document.getElementById("stacked-bar-chart");
    if (ctx) {
      const chartData = {
        labels: companyNames,
        datasets: modules.map((module, index) => ({
          label: module,
          data: companies.map((company) =>
            company[module.toLowerCase()] ? 1 : 0
          ),
          backgroundColor: colors[index],
        })),
      };

      const chart = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          scales: {
            x: { stacked: true, display: false },
            y: { stacked: true, display: false },
          },
          plugins: {
            legend: { position: "right" },
          },
        },
      });
      stackedBarChartRef.current = chart;
    }
  };

  const destroyChart = (chartRef) => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      renderModulePieChart();
      generateStackedBarChart();
    }
    return () => {
      destroyChart(modulePieChartRef);
      destroyChart(stackedBarChartRef);
    };
  }, [data, percent, plan]);

  return (
    <>
      <Container maxWidth="xl">
        <BreadCrumb />
        <Divider />
      </Container>
      <br />
      <Container maxWidth="xl">
        <Box sx={{ display: "flex", gap: 2,height:300 }}>
          <Card sx={{ flex: 1, padding: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              //sx={{ padding: 2 }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h6" onClick={() => handlePlan(null)}>
                  Modules
                </Typography>
                <SimCardIcon />
              </Stack>
              <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                <Typography
                  variant="h6"
                  color={plan != "basic" ? "grey" : "green"}
                  onClick={() => handlePlan("basic")}
                >
                  Basic
                </Typography>
                <Typography
                  variant="h6"
                  color={plan != "standard" ? "grey" : "blue"}
                  onClick={() => handlePlan("standard")}
                >
                  Standard
                </Typography>
                <Typography
                  variant="h6"
                  color={plan != "premium" ? "grey" : "orange"}
                  onClick={() => handlePlan("premium")}
                >
                  Premium
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Box
              sx={{
                width: "70%",
                marginTop: "-100px",
                //marginBottom: "-500px",
              }}
            >
              <canvas id="module-pie"></canvas>
            </Box>
          </Card>
          <Card sx={{ flex: 1, padding: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              //sx={{ padding: 2 }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h6">Recent Signups</Typography>
                <BusinessIcon />
              </Stack>
              <Button variant="outlined" href="/cma/clients">
                View All&nbsp;
                <ArrowForwardIosIcon />
              </Button>
            </Stack>
            <Divider />
            <canvas id="stacked-bar-chart"></canvas>
          </Card>
        </Box>
      </Container>
      <br />
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6" gutterBottom>
                Access Management
              </Typography>
              <RiOrganizationChart />
            </Stack>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={data.map((option) => option.company)}
              onChange={(event, newValue) => setSearchValue(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Company"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  // value={searchValue}
                  onChange={(event, newValue) =>
                    setSearchValue(event.target.value)
                  }
                  sx={{ width: 400 }}
                />
              )}
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 650, overflow: "auto", position: "relative" }}
          >
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="blue"
                  strokeWidth="5"
                  strokeColor="#062C41"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Box>
            ) : (
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead
                  sx={{
                    bgcolor: "#062C41",
                    "& .MuiTableCell-root": { color: "white" },
                  }}
                >
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>KMS</TableCell>
                    <TableCell>HRMS</TableCell>
                    <TableCell>PMS</TableCell>
                    <TableCell>ITAMS</TableCell>
                    <TableCell>All</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 &&
                    filteredData.map((company, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{company.company}</TableCell>
                        <TableCell>
                          {isModify ? (
                            company.kms ? (
                              "✓"
                            ) : (
                              "--"
                            )
                          ) : (
                            <Checkbox
                              checked={company.kms}
                              onClick={() => handleCheck(index, "kms")}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {isModify ? (
                            company.hrms ? (
                              "✓"
                            ) : (
                              "--"
                            )
                          ) : (
                            <Checkbox
                              checked={company.hrms}
                              onClick={() => handleCheck(index, "hrms")}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {isModify ? (
                            company.pms ? (
                              "✓"
                            ) : (
                              "--"
                            )
                          ) : (
                            <Checkbox
                              checked={company.pms}
                              onClick={() => handleCheck(index, "pms")}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {isModify ? (
                            company.itams ? (
                              "✓"
                            ) : (
                              "--"
                            )
                          ) : (
                            <Checkbox
                              checked={company.itams}
                              onClick={() => handleCheck(index, "itams")}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {isModify ? (
                            company.kms &&
                            company.hrms &&
                            company.pms &&
                            company.itams ? (
                              "✓"
                            ) : (
                              "--"
                            )
                          ) : (
                            <Checkbox
                              onClick={() => handleAllCheck(index)}
                              checked={
                                company.kms &&
                                company.hrms &&
                                company.pms &&
                                company.itams
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                mt: 2,
                mb: 2,
              }}
            >
              <Button
                variant={isModify ? "contained" : "outlined"}
                endIcon={<EditIcon />}
                onClick={() => setIsModify(false)}
                disabled={!isModify}
                sx={{
                  bgcolor: isModify ? "#062C41" : "transparent",
                }}
              >
                Modify
              </Button>
              {!isModify && (
                <Button
                  variant="contained"
                  endIcon={<CachedIcon />}
                  sx={{
                    bgcolor: "#062C41",
                  }}
                  onClick={handleUpdate}
                >
                  <Tooltip title="Are you sure?" sx={{ bgcolor: "#062C41" }}>
                    <span>Update</span>
                  </Tooltip>
                </Button>
              )}
            </Box>
          </TableContainer>
        </Container>
      </React.Fragment>
    </>
  );
};

export default Dashboard;
