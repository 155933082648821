import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../../constants/BreadCrumb";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  adminAllCompanylist,
  adminUpdateCompanydashboard,
} from "../../../constants/api";
import EditIcon from "@mui/icons-material/Edit";
import { RotatingLines } from "react-loader-spinner";

const Clients = () => {
  const pdfRef = useRef(null);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "company",
      headerName: "Company Name",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "custom-header",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "joindate",
      headerName: "Join Date",
      //type:"date",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "clientname",
      headerName: "Client Name",
      headerClassName: "custom-header",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "clientemail",
      headerName: "Client Mail ID",
      headerClassName: "custom-header",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleClientEdit(params);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const handleClientEdit = (params) => {
    setSelectedRow(params.row);
    setIsEditOpen(true);
  };

  //all company
  useEffect(() => {
    axios
      .post(adminAllCompanylist)
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setData(initialData);
      })
      .catch((err) => console.log(err));
  }, [loading]);

  useEffect(() => {
    if (!searchValue) {
      // If searchValue is empty, display all original data
      setFilteredData(data);
    } else {
      // Filter the original data based on searchValue
      const filtered = data.filter((company) =>
        company.company.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
      setFilteredData(filtered);
    }
  }, [searchValue, data]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await axios.post(adminUpdateCompanydashboard, {
        companyarray: [selectedRow],
      });
      if (res.status == 200) {
        handleEditCancel();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleEditCancel = () => {
    setIsEditOpen(false);
    setEditedRow(null);
    setLoading(false);
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedRow({ ...selectedRow, [name]: value });
  };

  return (
    <>
      <Container maxWidth="xl">
        <BreadCrumb />
        <Divider />
        <br />
      </Container>
      <Container maxWidth="xl">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            color={"#062C41"}
            fontWeight={"bold"}
            gutterBottom
          >
            Clients
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={data.map((option) => option.company)}
              onChange={(event, newValue) => setSearchValue(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  value={searchValue}
                  onChange={(event, newValue) =>
                    setSearchValue(event.target.value)
                  }
                  sx={{ width: 700 }}
                />
              )}
            />
            <DownloadTableExcel
              filename="Clients"
              sheet="Clients"
              currentTableRef={pdfRef.current}
            >
              <BiSolidFileExport
                fontSize={"30px"}
                cursor={"pointer"}
                color="#062C41"
              />
            </DownloadTableExcel>
          </Box>
        </Box>
        <br />
        {loading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="blue"
              strokeWidth="5"
              strokeColor="#062C41"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </Box>
        ) : (
          <DataGrid
            onRowClick={handleRowClick}
            ref={pdfRef}
            rows={filteredData.length > 0 && filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 8 },
              },
            }}
            pageSizeOptions={[8, 16, 32]}
          />
        )}
      </Container>
      {/* Modal to display selected row details */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ mt: 4, p: 1, bgcolor: "background.paper" }}>
            <Typography
              variant="h6"
              color={"#062C41"}
              fontWeight={600}
              gutterBottom
            >
              {selectedRow?.clientname}
            </Typography>
            <Divider />
            <Container maxWidth="md">
              {selectedRow && (
                <div>
                  <Typography
                    variant="subtitle1"
                    color={"#062C41"}
                    fontWeight={600}
                  >
                    {selectedRow?.company}
                  </Typography>
                  <Divider />
                  <Typography variant="subtitle1">
                    Total Users:&nbsp;&nbsp;&nbsp;&nbsp; 100
                  </Typography>
                  <Typography variant="subtitle1">
                    Total Admins:&nbsp;&nbsp;&nbsp;&nbsp; 10
                  </Typography>
                  <Typography variant="subtitle1">
                    Total Employees:&nbsp;&nbsp;&nbsp;&nbsp; 500
                  </Typography>
                  <Typography variant="subtitle1">
                    Modules:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {selectedRow?.basickms
                      ? "KMS(Basic)"
                      : selectedRow?.standardkms
                      ? "KMS(Standard)"
                      : selectedRow?.premiumkms && "KMS(Premium)"}
                    ,
                    {selectedRow?.basicpms
                      ? "PMS(Basic)"
                      : selectedRow?.standardpms
                      ? "PMS(Standard)"
                      : selectedRow?.premiumpms && "PMS(Premium)"}
                    ,
                    {selectedRow?.basichrms
                      ? "HRMS(Basic)"
                      : selectedRow?.standardhrms
                      ? "HRMS(Standard)"
                      : selectedRow?.premiumhrms && "HRMS(Premium)"}
                    ,
                    {selectedRow?.basicitams
                      ? "ITAMS(Basic)"
                      : selectedRow?.standarditams
                      ? "ITAMS(Standard)"
                      : selectedRow?.premiumitams && "ITAMS(Premium)"}
                  </Typography>
                  <br />
                  <Typography
                    variant="subtitle1"
                    color={"#062C41"}
                    fontWeight={600}
                  >
                    Ticket History
                  </Typography>
                  <Divider />
                  <br />
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        sx={{
                          bgcolor: "#062C41",
                          "& .MuiTableCell-root": { color: "white" },
                        }}
                      >
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell align="right">Ticket ID</TableCell>
                          <TableCell align="right">Ticket Date</TableCell>
                          <TableCell align="right">Module</TableCell>
                          <TableCell align="right">Description</TableCell>
                          <TableCell align="right">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/*rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                          </TableRow>
                        ))*/}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Container>
          </Box>
        </Container>
      </Modal>

      {/* Modal to Edit selected row details */}
      <Modal
        open={isEditOpen}
        //onClose={() => setIsEditOpen(false)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setIsEditOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          //alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ mt: 4, p: 1, bgcolor: "background.paper" }}>
            <Typography
              variant="h6"
              color={"#062C41"}
              fontWeight={600}
              gutterBottom
            >
              Edit Client
            </Typography>
            <Divider />
            <br />
            <Box justifyContent={"center"}>
              <TextField
                className="loginfields"
                required
                fullWidth
                id="outlined-required"
                label="Country"
                name="country"
                autoComplete="country"
                value={selectedRow?.country}
                onChange={handleChange}
                //error={!data.company && showEmptyError}
              />
              <TextField
                className="loginfields"
                required
                fullWidth
                id="outlined-required"
                label="Client Name"
                name="clientname"
                autoComplete="clientname"
                value={selectedRow?.clientname}
                onChange={handleChange}
                //error={!data.company && showEmptyError}
              />
              <TextField
                className="loginfields"
                required
                fullWidth
                id="outlined-required"
                label="Client Mail ID"
                name="clientemail"
                autoComplete="clientemail"
                value={selectedRow?.clientemail}
                onChange={handleChange}
                //error={!data.company && showEmptyError}
              />
            </Box>
            <Box justifyContent={"center"} gap={"10px"}>
              <Button
                variant="contained"
                //endIcon={<CachedIcon />}
                sx={{
                  bgcolor: "#062C41",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                //endIcon={<CachedIcon />}
                sx={{
                  bgcolor: "#ffffff",
                }}
                onClick={handleEditCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default Clients;
