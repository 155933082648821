import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { MdOutlineFileDownload } from "react-icons/md";
import axios from 'axios';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { vlivBackendApi } from '../../../constants/api';

function PurchaseHistory() {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [paymentData, setPaymentData] = useState({});

    //redux
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress } = useSelector((state) => state.userDataReducer.userData || {})


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns = [
        { id: 'createdon', label: 'Created On', minWidth: 125, align: 'center', },
        { id: 'invoiceno', label: 'Invoice No', minWidth: 120, align: 'center', },
        {
            id: 'plan',
            label: 'Plan',
            minWidth: 130,
            align: 'center',
        },
        {
            id: 'plantype',
            label: 'Plan Type',
            minWidth: 130,
            align: 'center',
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'addon',
            label: 'Add On',
            minWidth: 300,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'additionaluser',
            label: 'Additional User',
            minWidth: 170,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'ended',
            label: 'Ended',
            minWidth: 120,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'total',
            label: 'Total',
            minWidth: 120,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 100,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 50,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const body = {
                companyid: companyid,
                companyemail: companyemail
            }
            const response = await axios.post(`${vlivBackendApi}/company/getpaymentdata`, {}, {withCredentials:true});
            const paymentData = response.data;
            setPaymentData(paymentData);
        }
        catch (error) {
            console.log("Error in fetching payment status ", error)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    //search start
    const handleSearch = async (value) => {
        setLoading(true);
        setSearchKeyword(value);
    };

    const debouncedSearch = useCallback(_.debounce(() => {
        setLoading(false);
    }, 300), []);

    const filteredData = () => {
        if (paymentData.length > 0) { 
            debouncedSearch()
            return paymentData?.filter((item) =>
                Object.values(item)?.some(value =>
                    value?.toString()?.toLowerCase()?.includes(searchKeyword.toLowerCase().trim())
                )
            );
        }
        else {
            return [];
        }

    }

    const filterData = filteredData();

    //search end

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
                <div className='purchase'>
                    <div>
                        <h2 className='common-heading' >Purchase History</h2>
                    </div>
                    <div className='searchFeild'>
                        <span className='search-icon'><FaSearch /></span>
                        <input type="search" placeholder='Search... ' value={searchKeyword} onChange={(e) => handleSearch(e.target.value)} className='form-feild' />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className='purchase-table'>
                    <div style={{ height: 400, width: '100%' }}>
                        <TableContainer sx={{ maxHeight: 370 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className='tableheader'>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={10} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : filterData.length > 0 ? (
                                        filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            const {
                                                additionaluserprice,
                                                additionalusers,
                                                addonpack,
                                                addonpackprice,
                                                amount,
                                                companyemail,
                                                companyid,
                                                createdAt,
                                                date,
                                                id,
                                                invoiceid,
                                                merchantid,
                                                merchanttransactionid,
                                                planname,
                                                planprice,
                                                plansubscription,
                                                responseCode,
                                                state,
                                                tax,
                                                transactionId,
                                                type,
                                                updatedAt,
                                                upitransactionid,
                                                status,
                                                expiredate
                                            } = row;
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    colSpan={10}
                                                >
                                                    <TableCell>{date || "--"}</TableCell>
                                                    <TableCell>{invoiceid || "--"}</TableCell>
                                                    <TableCell>{planname || "--"}</TableCell>
                                                    <TableCell>{plansubscription || "--"}</TableCell>
                                                    <TableCell>
                                                        {addonpack && addonpack.length > 0
                                                            ? addonpack.map((item) => item.addonpackname || "--").join(", ")
                                                            : "--"}
                                                    </TableCell>
                                                    <TableCell>{additionalusers || "--"}</TableCell>
                                                    <TableCell>{expiredate || "--"}</TableCell>
                                                    <TableCell>{amount || "--"}</TableCell>
                                                    <TableCell>{state || "--"}</TableCell>
                                                    <TableCell><span><MdOutlineFileDownload /></span></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={9} align="center">
                                                <h2 >No Payment History Found</h2>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className='commonpagination'
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filterData.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </Grid>
        </Grid >
    )
}

export default PurchaseHistory