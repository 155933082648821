import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../Blog/JoditEditorStyles.css";
import Cookies from "js-cookie";
import axios from "axios";
import { companyUserGet } from "../../../../constants/api";
import { notifyError } from "../../../../constants/toastAlerts";
import { FaPencilAlt } from "react-icons/fa";

function ViewModal({ handleTrigger, rowData, refresh, setRefresh }) {
  const [purchaseData, setPurchaseData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isBillEditing, setIsBillEditing] = useState(false);

  const [formData, setFormData] = useState({ ...rowData });
  const [totalUsers, setTotalUsers] = useState("");
  const [userCount, setUserCount] = useState("");
  // const [refresh, setRefresh] = useState(false)
  const [planname, setPlanname] = useState((rowData && rowData.planname) || "");
  const [plansubscription, setPlansubscription] = useState(
    (rowData && rowData.plansubscription) || ""
  );
  const [expiredate, setExpiredate] = useState("");

  // console.log(expiredate);

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function formatDateToYYYYDDMM(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${day}-${month}`;
  }

  useEffect(() => {
    axios
      .post(`https://vliv.app/company/getpaymentdata`, {
        companyemail: rowData.clientemail,
      },
    {withCredentials:true})
      .then((res) => {
        setPurchaseData(res.data);

        setExpiredate(formatDateToYYYYDDMM(rowData.expiredate));
        // setPlanname(res.data[0].planname)
        // setPlansubscription(res.data[0].plansubscription)
        // console.log(res.data, "purchased data");
      })
      .catch((err) => console.log(err));

    // to get how many users invited API
    axios
      .post(companyUserGet, { companyemail: rowData.clientemail })
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setUserCount(res.data.data.length);
        }
      })
      .catch((err) => console.log(err));
  }, [rowData.clientemail, refresh]);

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleUpdate = () => {
    // console.log("Updated Row Data:", formData);
    if (expiredate == "") {
      return notifyError("Kindly choose due date");
    }
    if (planname == "") {
      return notifyError("Kindly select planname");
    }
    if (plansubscription == "") {
      return notifyError("Kindly select plan subscription");
    }
    axios
      .post(`https://vliv.app/company/updatecompanydata`, {
        ...formData,
        planname,
        plansubscription,
        expiredate: formatDateToDDMMYYYY(expiredate),
      })
      .then((res) => {
        setIsEditing(false);
        setRefresh(!refresh);
        handleTrigger();
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("rowdata", rowData);

  return (
    <Grid container sx={{ background: "#F4FEFE" }}>
      <Grid item sm={12} md={4} lg={4}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "start" }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Org Id: {rowData && rowData.id}
          </Typography>
        </Grid>
        <Divider />

        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            background: "#F4FEFE",
            padding: 4,
            mt: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              Joining Date
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              {rowData && new Date(rowData.createdAt).toDateString()}
            </Typography>
          </Box>
          {isEditing ? (
            <>
              <TextField
                label="Organization Name"
                name="company"
                value={formData.company}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Client Name"
                name="clientname"
                value={formData.clientname}
                onChange={handleChange}
                fullWidth
              />
              {/* <TextField
                label="Organization Size"
                name="users"
                value={formData.users}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Additional Users"
                name="additionaluser"
                value={formData.additionaluser || ""}
                onChange={handleChange}
                fullWidth
              /> */}
              <TextField
                label="Mail"
                name="clientemail"
                value={formData.clientemail}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Phone"
                name="phoneno"
                value={formData.phoneno || ""}
                onChange={handleChange}
                fullWidth
              />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Organization Name:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {rowData && rowData.company}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Client Name:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {rowData && rowData.clientname}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Organization Size:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {rowData && +rowData.users + (+rowData.additionaluser || 0)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Total Users:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {userCount}/
                  {rowData && +rowData.users + (+rowData.additionaluser || 0)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Mail:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {rowData && rowData.clientemail}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Phone:
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  {rowData && rowData.phoneno}
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item sm={12} md={7.8} lg={7.8}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Status: {rowData.planstatus}
          </Typography>
        </Grid>
        <Divider />
        <Typography sx={{ ml: 4, fontSize: "14px", fontWeight: "600" }}>
          Plan Details{" "}
          <Button onClick={() => setIsBillEditing(true)}>
            <FaPencilAlt
              style={{ width: "18px", height: "18px", color: "black" }}
            />
          </Button>
        </Typography>
        {isBillEditing && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingLeft: 5,
              paddingRight: 5,
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <label style={{ fontSize: "12px" }}>Plan due date</label>
              <TextField
                type="date"
                value={expiredate}
                onChange={(e) => setExpiredate(e.target.value)}
              />
            </FormControl>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Choose plan type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={planname}
                  label="Choose Plan"
                  onChange={(e) => setPlanname(e.target.value)}
                >
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="Standard">Standard</MenuItem>
                  <MenuItem value="Premium">Premium</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Choose plan month
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={plansubscription}
                  label="Choose Plan"
                  onChange={(e) => setPlansubscription(e.target.value)}
                >
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsBillEditing(false)}
              >
                cancel
              </Button>
            </Box>
          </Box>
        )}

        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            background: "#F4FEFE",
            padding: 4,
            mt: 3,
            // maxHeight: "300px",
            // overflowY: "auto",
          }}
        >
          <Accordion
            sx={{ mb: 1, boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
          >
            <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            // aria-controls="panel1-content"
            // id="panel1-header"
            >
              <Grid
                sm={12}
                md={12}
                lg={12}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Date {rowData && rowData.purchasedate}</span>{" "}
                  <span style={{ fontSize: "16px", fontWeight: "400" }}>
                    Due date - {rowData && rowData.expiredate}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    gap: 2,
                    paddingTop: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ display: "flex", gap: 2 }}>
                    <p
                      style={{
                        fontSize: "14px",
                        background: "#B2DDFF",
                        color: "#2E90FA",
                        padding: "4px",
                        borderRadius: "10px",
                      }}
                    >
                      {rowData && rowData.plansubscription}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "14px",
                        background: "#E6DFF7",
                        color: "#7E5FBE",
                        padding: "4px",
                        borderRadius: "10px",
                      }}
                    >
                      {rowData && rowData.planname}
                    </p>
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "550", color: "green" }}
                  >
                    <span>Active Plan</span>
                  </Typography>
                </Typography>
              </Grid>
            </AccordionSummary>
          </Accordion>
          <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
            History
          </Typography>
          {!isBillEditing && <Box
            sx={{
              background: "#F4FEFE",
              padding: 4,
              // mt: 3,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {purchaseData.length > 0 &&
              purchaseData.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Date {item.date}</span>{" "}
                        <span style={{ fontSize: "16px", fontWeight: "400" }}>
                          Due date - {item.expiredate}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          gap: 2,
                          paddingTop: 2,
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ display: "flex", gap: 2 }}>
                          <p
                            style={{
                              fontSize: "14px",
                              background: "#B2DDFF",
                              color: "#2E90FA",
                              padding: "4px",
                              borderRadius: "10px",
                            }}
                          >
                            {(item.plansubscription && item.plansubscription) ||
                              "Free Trial"}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "14px",
                              background: "#E6DFF7",
                              color: "#7E5FBE",
                              padding: "4px",
                              borderRadius: "10px",
                            }}
                          >
                            {item.planname}
                          </p>
                        </Typography>
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "550" }}
                        >
                          <span>RS</span> <span>{item.planprice}</span>
                        </Typography>
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <hr />
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      Add Ons
                    </Typography>
                    <Box>
                      {item.addonpack &&
                        item.addonpack.length > 0 &&
                        item.addonpack.map((itm, idx) => (
                          <ul
                            style={{
                              listStyle: "disc inside",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                              padding: 0,
                            }}
                            key={idx}
                          >
                            <li style={{ listStyle: "disc inside" }}>
                              {itm.addonpackname}
                            </li>
                          </ul>
                        ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>}
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Grid item sm={12} md={6}>
          {isEditing ? (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.6 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                cancel
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" onClick={handleEdit}>
              Edit
            </Button>
          )}
        </Grid>
        <Grid item sm={12} md={6}></Grid>
      </Grid>
    </Grid>
  );
}

export default ViewModal;
