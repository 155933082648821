import { Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel/CustomTabPanel';
import BillingPlan from './BillingPlan';
import PurchaseHistory from './PurchaseHistory';
import { plans } from '../../../redux/actions/plans.action';

function Settings() {
    const navigate = useNavigate();
    //redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    const loadData = useSelector((state) => state.loadReducer);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress,
    } = useSelector((state) => state.userDataReducer.userData || {});
    const planData = useSelector((state) => state.planReducer.planData);

    useEffect(() => {
        dispatch(plans({ "email": email }));
    }, []);

    const settingspages = [
        {
            path: "/frontend/settings",
            name: "Billing & Plan"
        },
        {
            path: "/frontend/settings/purchaseHistory",
            name: "Purchase History"
        },
    ]
    return (
        <>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <div className='setting-page'>
                        <h2 className='common-heading'>Settings</h2>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className='setting-tab'>
                        {/* <div className='setting-btn'> */}
                            {settingspages.map((item, index) => {
                                return (
                                        <NavLink
                                            to={item.path}
                                            key={index}
                                            className={({ isActive }) => (isActive ? 'active' : '')}
                                            end
                                        >
                                            {item.name}
                                        </NavLink>
                                );
                            })}
                        {/* </div> */}

                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Outlet />
                </Grid>
            </Grid >

        </>
    )
}

export default Settings;