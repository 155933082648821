import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../constants/Loader';
import ReactToPrint from 'react-to-print';
import brandLogo from "../../../../assets/Vliv-color 1.webp";
import { State } from 'country-state-city';
import axios from 'axios';
import { commaSeparatedPrice } from '../../../../constants/commaSeparatedPrice';

function PaymentSucess() {
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress } = useSelector((state) => state.userDataReducer.userData || {})
  // console.log("pan", pan);

  const [params, setParams] = useState({
    transactionid: '',
    merchantid: '',
    date: '',
    amount: '',
    pay: ''
  });
  const [loadData, setLoadData] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const containerRef = useRef(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    const transactionid = pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
    const merchantid = pathParts.find(part => part.startsWith('merchantid=')).split('=')[1];
    const date = pathParts.find(part => part.startsWith('date=')).split('=')[1];
    const amount = pathParts.find(part => part.startsWith('amount=')).split('=')[1];
    const pay = pathParts.find(part => part.startsWith('pay=')).split('=')[1];
    setParams({ transactionid, merchantid, date, amount, pay });
    fetchData();
  }, []);

  function getTransactionId() {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    return pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
  }

  const fetchData = async () => {
    try {
      const body = {
        companyid: companyid,
        companyemail: companyemail
      }
      const response = await axios.post('/company/getpaymentdata', body, {withCredentials:true});
      // console.log("response", response)
      const paymentData = response.data.find((item) => item.transactionId == getTransactionId());
      
      setPaymentData(paymentData);
    }
    catch (error) {
      console.log("Error in fetching payment status ", error)
    }
  }

  const handleApps = () => {
    setLoadData(true);
    setTimeout(() => {
      setLoadData(false);
      navigate("/frontend/apps")
    }, 1500)

  }

  const getSubTotalAmount = !paymentData?.isadditional
    ? Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice)
    : Number(paymentData?.additionalusercost) * Number(paymentData?.extrausers);
  const getTotalAmount = Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice) + Number(paymentData?.tax);

  return (
    <div className={`section-payment ${theme}`}>
      <div className='payment-container grid'>
        <span className='payment-icon'><FaCircleCheck /></span>
        <h1 className='payment-status-heading'>Payment Success !</h1>
        <ul className='payment-details'>
          <li className=''>
            <span>Transaction ID:</span>
            <span>{params.transactionid}</span>
          </li>
          <li className=''>
            <span>Merchant ID:</span>
            <span>{params.merchantid}</span>
          </li>
          <li className=''>
            <span>Date:</span>
            <span>{params.date}</span>
          </li>
          <li className=''>
            <span>Payment Mode:</span>
            <span>{params.pay}</span>
          </li>
          <li className=''>
            <span>Amount:</span>
            <span>INR {commaSeparatedPrice(+params.amount)}</span>
          </li>
        </ul>
        <div className='payment-button'>
          <ReactToPrint
            trigger={() => (
              <button className='commonBtn'>Get Invoice</button>
            )}
            content={() => containerRef.current}
          />

          <button className='commonBtn purchased' onClick={handleApps}>{loadData ? <Loader /> : "Go to Apps"}</button>
        </div>
      </div>

      {/* print invoice*/}
      <div style={{ display: "none" }}>
        <div ref={containerRef} className='print-section'>
          <div>
            <img src={brandLogo} alt='Vliv logo not available' width={100} height="auto" />
            <h2 className='print-heading'>Payment Receipt</h2>
          </div>
          <hr className='row-line' />
          <div className='payment-details--container'>
            <div className='payment-address--details'>
              <div>
                <h2 className='payment-details-heading'>From</h2>
                <div>
                  <p className='payment-details-para'>Venturesathi Business Services LLP</p>
                  <p className='payment-details-para'>Office no: 220, Second Floor, Forum Galaria, Civil Township</p>
                  <p className='payment-details-para'> Rourkela, Odisha, 769004</p>
                  <p className='payment-details-para'>India</p>
                  <p className='payment-details-para'>PAN : AAVFV9926B</p>
                  <p className='payment-details-para'>GST : 21AAVFV996B1ZV</p>
                </div>
              </div>
              <div>
                <h2 className='payment-details-heading'>To</h2>
                <div>
                  <p className='payment-details-para'>{companyname}</p>
                  <p className='payment-details-para'>{address}</p>
                  <p className='payment-details-para'>{address2}</p>
                  <p className='payment-details-para'>{city} {state}, {pincode}</p>
                  <p className='payment-details-para'>{country} - {countrycode}</p>
                  <p className='payment-details-para'>PAN : {pan}</p>
                  <p className='payment-details-para'>GST : {companygst}</p>
                </div>
              </div>
            </div>
            <div>
              <h2 className='payment-details-heading'>Details</h2>
              <ul className='payment-details'>
                <li className=''>
                  <span>Invoice ID:</span>
                  <span>{paymentData?.invoiceid}</span>
                </li>
                <li className=''>
                  <span>Payment Date:</span>
                  <span>{paymentData?.date}</span>
                </li>
                <li className=''>
                  <span>Type:</span>
                  <span>{paymentData?.planname}({paymentData?.plansubscription})</span>
                </li>
              </ul>

              <h2 className='payment-details-heading'>Payment Details</h2>
              <ul className='payment-details'>
                <li className=''>
                  <span>Transaction ID:</span>
                  <span>{paymentData?.transactionId}</span>
                </li>
                <li className=''>
                  <span>Merchant ID:</span>
                  <span>{paymentData?.merchantid}</span>
                </li>
                <li className=''>
                  <span>Merchant Transaction ID:</span>
                  <span>{paymentData?.merchanttransactionid}</span>
                </li>
                <li className=''>
                  <span>Payment Mode:</span>
                  <span>{paymentData?.type}</span>
                </li>
              </ul>
            </div>
          </div>

          <hr className='row-line' />

          <div className='payment-details--container'>
            <div>
              <h2 className='payment-details-heading'>Plan</h2>
              <p className='payment-details-para'> {!paymentData?.isadditional ? paymentData?.planname : "Per User Cost"}</p>
              <p className='payment-details-para'>{!paymentData?.isadditional ? `Additional User ${paymentData?.additionalusers}` : "Selected Additional Users"}</p>
              {
                paymentData?.addonpack?.map((item, index) => {
                  return (
                    <p className='payment-details-para' key={index}>{item.addonpackname}</p>
                  )
                })
              }

            </div>
            <div>
              <h2 className='payment-details-heading'>Amount</h2>
              <div className='amount-container'>
                <p className='payment-amount'>&#8377; {!paymentData?.isadditional ? commaSeparatedPrice(paymentData?.planprice) : paymentData?.additionalusercost}</p>
                {!paymentData?.isadditional ? <p className='payment-amount'>&#8377; {commaSeparatedPrice(paymentData?.additionaluserprice)}</p> :
                  <p className='payment-amount'>{paymentData?.extrausers}</p>}
                {
                  paymentData?.addonpack?.map((item, index) => {
                    return (
                      <p className='payment-amount' key={index}>&#8377; {commaSeparatedPrice(item.addonprice)}</p>
                    )
                  })
                }

              </div>
            </div>
          </div>
          <div className='payment-details--container payment-subtotal'>
            <div>
              <ul className='subtotal-heading--container'>
                <li className='payment-details-para'>Sub-Total</li>
                <li className='payment-details-para'>TAX</li>
              </ul>
            </div>
            <div>
              <ul >
                <li className='payment-amount'>&#8377; {commaSeparatedPrice(getSubTotalAmount)}</li>
                <li className='payment-amount'>&#8377; {commaSeparatedPrice(paymentData?.tax)}</li>
              </ul>
            </div>
          </div>

          <hr className='row-line' />
          <div className='payment-details--container'>
            <div>
              <h2 className='payment-details-heading'>Total</h2>
            </div>
            <div>
              <h2 className='payment-details-heading bold'>&#8377; {!paymentData?.isadditional ? commaSeparatedPrice(getTotalAmount) : paymentData?.amount}</h2>
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default PaymentSucess