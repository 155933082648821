export const formattedDate = (datestring) => {
    const date = new Date(datestring);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}