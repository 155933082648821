import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Divider,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Cookies from "js-cookie";
import { responsiveDrawer } from "../../../../constants/constants";
import axios from "axios";
import {
  companyUserAdd,
  companyUserUpdate,
  hrmsDomain,
} from "../../../../constants/api";
import { notifyError, notifySuccess } from "../../../../constants/toastAlerts";
import { reloadPage } from "../../../../redux/actions/load.action";
import { useDispatch } from "react-redux";

const EditUser = ({
  handleEditDrawerClose,
  editDrawerOpen,
  selectedUser,
  setSelectedUser,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userrole = userData.userrole ? userData.userrole : "";
  let totalmodules = ["HRMS", "KLMS"];
  let email = userData.email ? userData.email : "";
 const dispatch = useDispatch();
  const [showEmptyError, setShowEmptyError] = useState(false);

  const [allEmp, setAllEmp] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [attendanceSchemeOptions, setAttendanceSchemeOptions] = useState([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
  const [branchoptions, setBranchOptions] = useState([]);
  const [locationOptions, setLocationoptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [reportingManagerOptions, setReportingManagerOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [editMode, setEditMode] = useState({
    // userrole: false,
    userprofile: false,
    reportmanager: false,
    emptype: false,
    position: false,
    level: false,
    branch: false,
    location: false,
    attendancescheme: false,
    department: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddOption = (field) => () => {
    setEditMode((prevState) => ({
      ...prevState,
      [field]: true,
    }));
    setSelectedUser((prevState) => ({
      ...prevState,
      [field]: "",
    }));
  };

  const handleSaveOption = (field) => () => {
    setEditMode((prevState) => ({
      ...prevState,
      [field]: false,
    }));

    const addOptionIfNotExists = async (
      optionList,
      setOptionList,
      newOption,
      title
    ) => {
      if (newOption && !optionList.includes(newOption)) {
        try {
          let res = await axios.post(`${hrmsDomain}/employee/position/add`, {
            companyemail,
            companyid,
            title,
            name: newOption,
            status: "Active",
          });
          if (res.data.message == "Employee master added successfully") {
            setOptionList([...optionList, newOption]);
          }
        } catch (error) {
          console.log(error, "error");
        }
      }
    };

    switch (field) {
      case "userprofile":
        addOptionIfNotExists(
          profileOptions,
          setProfileOptions,
          selectedUser.userprofile,
          "profile"
        );
        break;
      case "reportmanager":
        addOptionIfNotExists(
          reportingManagerOptions,
          setReportingManagerOptions,
          selectedUser.reportmanager,
          "reportingmanager"
        );
        break;
      case "emptype":
        addOptionIfNotExists(
          employeeTypeOptions,
          setEmployeeTypeOptions,
          selectedUser.emptype,
          "employeetype"
        );
        break;
      case "position":
        addOptionIfNotExists(
          positionOptions,
          setPositionOptions,
          selectedUser.position,
          "position"
        );
        break;
      case "level":
        addOptionIfNotExists(
          levelOptions,
          setLevelOptions,
          selectedUser.level,
          "level"
        );
        break;
      case "branch":
        addOptionIfNotExists(
          branchoptions,
          setBranchOptions,
          selectedUser.branch,
          "branch"
        );
        break;
      case "location":
        addOptionIfNotExists(
          locationOptions,
          setLocationoptions,
          selectedUser.location,
          "location"
        );
        break;
      case "attendancescheme":
        addOptionIfNotExists(
          attendanceSchemeOptions,
          setAttendanceSchemeOptions,
          selectedUser.attendancescheme,
          "attendancescheme"
        );
        break;
      case "department":
        addOptionIfNotExists(
          departmentOptions,
          setDepartmentOptions,
          selectedUser.department,
          "department"
        );
        break;
      default:
        break;
    }
  };

  const getDropdowns = async () => {
    try {
      let res = await axios.post(`${hrmsDomain}/employee/position/get`, {
        companyemail,
        companyid,
      });
      //  console.log(res,"dropdown")
      if (res.data && res.data.length > 0) {
        const optionsMapping = {
          attendancescheme: setAttendanceSchemeOptions,
          reportingmanager: setReportingManagerOptions,
          position: setPositionOptions,
          employeetype: setEmployeeTypeOptions,
          branch: setBranchOptions,
          location: setLocationoptions,
          profile: setProfileOptions,
          level: setLevelOptions,
          department: setDepartmentOptions,
        };

        Object.keys(optionsMapping).forEach((key) => {
          optionsMapping[key](
            res.data
              .filter((opt) => opt.position === key && opt.status == "Active")
              .map((ele) => ele.name)
          );
        });
      }
    } catch (error) {
      console.log(error, "error");
      notifyError(error.message);
    }
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(`${hrmsDomain}/employee/get`, {
        companyid,
        companyemail,
      });
      res.data && res.data.length ? setAllEmp(res.data) : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
      notifyError(error.message);
    }
  };

  const handleAddUser = async () => {
    if (
      !selectedUser.userid ||
      !selectedUser.username ||
      !selectedUser.email ||
      !selectedUser.userphone ||
      !selectedUser.reportmanager ||
      selectedUser.usermodules.length == 0
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    try {
      let res = await axios.post(companyUserUpdate, selectedUser);
      dispatch(reloadPage(true));
      if (res.data.message == "User updated successfully") {
        notifySuccess(res.data.message);
        dispatch(reloadPage(false));
        handleEditDrawerClose();
      } else {
        dispatch(reloadPage(false));
        notifyError(res.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      notifyError(error.response.data.message);
    
    }
  };

  useEffect(() => {
    getDropdowns();
    getAllEmp();
  }, [editDrawerOpen]);

  return (
    <>
      <Drawer
        anchor="right"
        open={editDrawerOpen}
        onClose={handleEditDrawerClose}
        sx={responsiveDrawer}
      >
        <Box width={"100%"}>
          <Typography
            variant="h4"
            component={"h2"}
            gutterBottom
            px={2}
            py={1}
            className="primarycolor"
          >
            Edit User
          </Typography>
          <Divider />
          <form>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="User ID"
                  value={selectedUser.userid}
                  fullWidth
                  name="userid"
                  // onChange={handleInputChange}
                  disabled
                  error={!selectedUser.userid && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="Name"
                  name="username"
                  placeholder="Enter Full Name"
                  value={selectedUser.username}
                  onChange={handleInputChange}
                  fullWidth
                  error={!selectedUser.username && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Enter Email Id"
                  value={selectedUser.email}
                  onChange={handleInputChange}
                  fullWidth
                  error={!selectedUser.email && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="Phone No."
                  name="userphone"
                  placeholder="Enter phone no"
                  value={selectedUser.userphone}
                  onChange={handleInputChange}
                  fullWidth
                  error={!selectedUser.userphone && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel>Reporting Manager</InputLabel>
                  <Select
                    name="reportmanager"
                    id="demo-simple-select"
                    label="Reporting Manager"
                    value={selectedUser.reportmanager}
                    onChange={handleSelectChange}
                    fullWidth
                    error={!selectedUser.reportmanager && showEmptyError}
                  >
                    {allEmp
                      ?.filter(
                        (emp) =>
                          emp.role.toLowerCase() == "masteradmin" ||
                          emp.role.toLowerCase() == "manager"
                      )
                      .map((em) => (
                        <MenuItem value={em.name} key={em.id}>
                          {em.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel>Access Role</InputLabel>
                  <Select
                    name="userrole"
                    id="demo-simple-select"
                    label="Role"
                    value={selectedUser.userrole}
                    onChange={handleSelectChange}
                    fullWidth
                    error={!selectedUser.userrole && showEmptyError}
                  >
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"HR"}>HR</MenuItem>
                    <MenuItem value={"Manager"}>Manager</MenuItem>
                    {selectedUser.userrole=="masteradmin" && (
                      <MenuItem value={"masteradmin"}>masteradmin</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  
                  {editMode.department ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="department"
                        label="Department"
                        value={selectedUser.department}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("department")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
<>
                    <InputLabel>Department</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="department"
                      label="Department"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.department}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("department")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {departmentOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                 
                  {editMode.userprofile ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="userprofile"
                        label="Profile"
                        value={selectedUser.userprofile}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("userprofile")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                    <InputLabel>Profile</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="userprofile"
                      label="Profile"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.userprofile}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("userprofile")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {profileOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                 
                  {editMode.emptype ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="emptype"
                        value={selectedUser.emptype}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("emptype")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
<>
                    <InputLabel>Employee Type</InputLabel>
                    <Select
                      name="emptype"
                      id="demo-simple-select"
                      label="Employee Type"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.emptype}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("emptype")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {employeeTypeOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                 
                  {editMode.position ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="position"
                        value={selectedUser.position}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("position")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
<>
                    <InputLabel>Position</InputLabel>
                    <Select
                      name="position"
                      id="demo-simple-select"
                      label="Employee Type"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.position}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("position")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {positionOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                 
                  {editMode.level ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="level"
                        value={selectedUser.level}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("level")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                    <InputLabel>Level</InputLabel>
                    <Select
                      name="level"
                      label="Employee Type"
                      id="demo-simple-select"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.level}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("level")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {levelOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>OT Applicable</InputLabel>
                  <Select
                    name="otapplicable"
                    id="demo-simple-select"
                    label="OT Applicable"
                    value={selectedUser.otapplicable}
                    onChange={handleSelectChange}
                    fullWidth
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                 
                  {editMode.branch ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="branch"
                        value={selectedUser.branch}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("branch")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                    <InputLabel>Branch</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="branch"
                      label="Employee Type"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.branch}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("branch")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {branchoptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  
                  {editMode.location ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="location"
                        value={selectedUser.location}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("location")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                    <InputLabel>Location</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="location"
                      label="Employee Type"
                      IconComponent={() => null}
                      sx={{ pr: 0 }}
                      value={selectedUser.location}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleAddOption("location")}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {locationOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Attendance Scheme</InputLabel>
                  {editMode.attendancescheme ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="attendancescheme"
                        value={selectedUser.attendancescheme}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton
                        onClick={handleSaveOption("attendancescheme")}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Select
                      id="demo-simple-select"
                      name="attendancescheme"
                      label="Employee Type"
                      value={selectedUser.attendancescheme}
                      onChange={handleSelectChange}
                      endAdornment={
                        <IconButton
                          onClick={handleAddOption("attendancescheme")}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      {attendanceSchemeOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  // sx={{
                  //   "& .MuiAutocomplete-inputRoot": { height: "auto" },
                  //   "& .MuiChip-root": { marginTop: "-10px" },
                  // }}
                  aria-expanded={true}
                  multiple
                  id="tags-outlined"
                  options={totalmodules.map(
                    (plan) => plan
                    //plan.replace(/basic|standard|premium/g, "")
                  )}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  // className="formInputs"
                  value={selectedUser.usermodules}
                  onChange={(event, newValue) => {
                    setSelectedUser({ ...selectedUser, usermodules: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      MuiInputBase-input
                      sx={{
                        "& .MuiInputBase-input": { marginTop: "-10px" },
                      }}
                      fullWidth
                      className="formInputs"
                      aria-expanded={true}
                      placeholder="Module"
                      value={selectedUser.usermodules}
                      onChange={handleInputChange}
                      name="usermodules"
                      error={
                        selectedUser.usermodules.length == 0 && showEmptyError
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                className="saveBtn text-transform-cap"
                sx={{ mt: 2 }}
                onClick={handleAddUser}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export default EditUser;
